<!--  -->
<template>
  <div class="liveVideo flex-align-center">
    <div class="livePanelMain flex-column-center">
      <div class="title flex-align-between">
        <div class="icon"></div>
        <div class="text">{{$route.query.name}}</div>
        <div class="icon"></div>
      </div>
      <div id="player"></div>
    </div>
    <div class="back flex-align-between">
      <img :src="require('../../assets/task/return.png')" @click="goback" />
      <span @click="goback">返回</span>
    </div>
  </div>
</template>

<script>
  import entrance from "../../Common_components/head/entrance";
  export default {
    components: {
      entrance,
    },
    data() {
      return {
        // player: null,
        Url: "",
        player: "",
      };
    },
    //方法
    methods: {
      goback() {
        this.$router.go(-1);
      },
      async loadPlayer() {
        this.player = new polyvPlayer({
          wrap: "#player",
          hideSwitchPlayer: true,
          url: this.$route.query.Url,
          // url: "https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218114723HDu3hhxqIT.mp4",
        });
      },
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {},
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
      this.loadPlayer();
      let dom = document.getElementsByTagName('video')[0];
      dom['disablePictureInPicture'] = true;
    },
  };
</script>
<style lang='less' scoped>
  @import "./less/liveVideo";
</style>